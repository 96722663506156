//
// Dot badge
//


// General styles

.badge-dot {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    text-transform: none;

    strong {
        color: $gray-800;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        width: .375rem;
        height: .375rem;
        border-radius: 50%;
        margin-right: .375rem;
    }

    &.badge-md {
        i {
            width: .5rem;
            height: .5rem;
        }
    }

    &.badge-lg {
        i {
            width: .625rem;
            height: .625rem;
        }
    }
}
