//
// Custom toggle
//

.custom-toggle {
    position: relative;
    display: inline-block;
    width: $custom-toggle-width;
    height: 1.5rem;

    input {
        display: none;

        &:checked {
             + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-checked-border-color;

                &:before {
                    background: $custom-toggle-checked-bg;
                    transform: translateX(1.625rem);
                }
            }
        }

        &:disabled {
            + .custom-toggle-slider {
                border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;
            }

            &:checked {
                + .custom-toggle-slider {
                    border: $custom-control-indicator-border-width solid $custom-control-indicator-disabled-bg;

                    &:before {
                        background-color: lighten($custom-control-indicator-checked-bg, 10%);
                    }
                }
            }
        }
    }
}

.custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $custom-control-indicator-border-width solid $input-border-color;
    border-radius: 34px !important;
    background-color: transparent;


    &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 2px;
        bottom: 2px;
        border-radius: 50% !important;
        background-color: $custom-toggle-slider-bg;
        transition: $input-transition;
    }
}
