//
// Card with blockquote
//

.card-blockquote {
    padding: 2rem;
    position: relative;

    .svg-bg {
        display: block;
        width: 100%;
        height: 95px;
        position: absolute;
        top: -94px;
        left: 0;
    }
}
