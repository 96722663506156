@mixin badge-variant($bg) {
	color: saturate(darken($bg, 10%), 10);
    background-color: transparentize(lighten($bg, 25%), .5);

  	&[href] {
	    @include hover-focus {
	        color: color-yiq($bg);
	        text-decoration: none;
	        background-color: darken($bg, 10%);
	    }
	}
}
