.center-block-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f1f1f1;
}

.container-login a {
  text-decoration: none;
}

.login-title { 
  font-size: 40px;
  font-family: 'Fira Sans', sans-serif;
  text-align: center;
}

.gray {
  color: #333333;
}